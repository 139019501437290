





















































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import PickupTextInput from '../components/PickupTextInput/index.vue'
import controller from '@/app/ui/controllers/PickupController'
import { PickupDetail, PickupDetailSnapshot } from '@/domain/entities/Pickup'
import { EventBus, EventBusConstants, Utils } from '@/app/infrastructures/misc'
import { EnumPickupFlag } from '@/app/infrastructures/misc/Constants/pickup'
import router from '@/app/ui/router'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import Badge from '@/app/ui/components/Badge/index.vue'
import IconWeight from '@/app/ui/assets/ics_o_weight.vue'
import IconBookmark from '@/app/ui/assets/ics_o_bookmark.vue'

@Component({
  components: {
    LoadingOverlay,
    PickupTextInput,
    Button,
    EmptyState,
    Badge,
    IconWeight,
    IconBookmark,
  },
})
export default class PickupDetailPage extends Vue {
  enumPickupFlag = EnumPickupFlag
  utils = Utils

  created(): void {
    controller.getPickupDetail(this.$route.params.shipmentId)
    controller.getPickupSnapshotDetail(this.$route.params.shipmentId)
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    EventBus.$on(
      EventBusConstants.CANCEL_PICKUP_SUCCESS,
      (success: boolean) => {
        Vue.notify({
          title: 'Pickup Cancelled',
          text: `Order ${controller.pickupDetail.shipmentId} is successfully cancelled`,
          type: 'success',
          duration: 5000,
        })

        if (success) {
          router.push({
            name: `Pickup${this.pickupDetail.flag}`,
            query: { refetch: 'true' },
          })
        }
      }
    )
  }

  mounted(): void {
    if (this.isFromGlobalSearch) {
      this.onSetDisplayBreadcrumb(false)
    }
  }

  cancelReason = ''
  hasChanged = {
    cancelReason: false,
  }

  get pickupDetail(): PickupDetail {
    return controller.pickupDetail
  }

  get pickupSnapshotDetail(): PickupDetailSnapshot[] {
    return controller.pickupSnapshotDetail
  }

  get isLoading(): boolean {
    return controller.isLoading
  }

  get statusId(): string {
    return (
      (this.$route.meta.additional.statusId as string) ||
      this.pickupDetail.flag ||
      '-'
    )
  }

  get shipmentIds(): string {
    if (
      this.pickupDetail.shipmentGroupIds &&
      this.pickupDetail.shipmentGroupIds.length > 0
    ) {
      return this.pickupDetail.shipmentGroupIds.join('; ')
    }
    return this.pickupDetail.shipmentId || '-'
  }

  get origin(): string {
    return `${this.pickupDetail.pickup?.district}, ${this.pickupDetail.pickup?.subDistrict}, ${this.pickupDetail.pickup?.village}`
  }

  get shipmentDate(): string {
    if (!this.pickupDetail.createdAt) {
      return ''
    }

    return Utils.formatTimeZone(
      Utils.formatDateWithIDLocale(
        this.pickupDetail.createdAt,
        'DD MMM YYYY HH:mm Z'
      )
    )
  }

  get isCancelPickup(): boolean {
    return this.$route.meta.additional?.isCancel ? true : false
  }

  get markAsDoneRouteTo(): string {
    if (this.pickupDetail.flag === this.enumPickupFlag.CRROTW) {
      return 'PickupCRROTWMarkAsDone'
    }

    if (this.pickupDetail.flag === this.enumPickupFlag.DOLOTW) {
      return 'PickupCRROTWMarkAsDone'
    }

    if (this.pickupDetail.flag === this.enumPickupFlag.CRRPIC) {
      return 'PickupCRRPICMarkAsDone'
    }

    return ''
  }

  get showMarkAsDoneButton(): boolean {
    if (
      this.pickupDetail.histories &&
      this.pickupDetail.histories.length !== 0
    ) {
      if (
        this.pickupDetail.flag === this.enumPickupFlag.CRRPIC &&
        !this.isNotPickupDetail
      ) {
        return true
      }

      if (
        this.pickupDetail.flag === this.enumPickupFlag.CRROTW &&
        !this.isNotPickupDetail
      ) {
        return true
      }

      if (
        this.pickupDetail.flag === this.enumPickupFlag.DOLOTW &&
        !this.isNotPickupDetail
      ) {
        return true
      }
    }

    return false
  }

  get isNotPickupDetail(): boolean {
    return (
      this.$route.name === 'PickupAdditionalTimeDetail' ||
      this.$route.name === 'PickupRadiusValidationShipmentDetail' ||
      this.$route.name === 'SearchShipmentDetailPage' ||
      this.$route.name === 'CancelValidationShipmentDetail'
    )
  }

  get isFromGlobalSearch(): boolean {
    return this.$route.name === 'SearchShipmentDetailPage'
  }

  get isShowEmptyState(): boolean {
    return (
      !this.isLoading &&
      this.isFromGlobalSearch &&
      !this.pickupDetail.shipmentId
    )
  }

  get chargeableWeight(): string {
    return this.pickupDetail.weight !== undefined
      ? `${Number((this.pickupDetail.weight / 1000).toFixed(1))} kg`
      : '-'
  }

  get productTypes(): string[] {
    const productTypes: string[] = this.isCorporate
    ? (<string[]>this.pickupDetail.productType)[0].split(',')
    : <string[]>this.pickupDetail.productType
    return <string[]>(
      productTypes.map(type => {
        if (type === 'SAMEDAY') {
          return 'same day'
        }

        return type.toLocaleLowerCase()
      })
    ) || []
  }

  get isCorporate(): boolean {
    return (<string>this.pickupDetail.shipmentId).substr(0, 2) === 'CP'
  }

  getShowGroupID(): boolean {
    return Boolean(
      this.pickupDetail.groupId &&
        this.pickupDetail.flag !== this.enumPickupFlag.CRRDON &&
        this.pickupDetail.flag !== this.enumPickupFlag.DOX
    )
  }

  private historyDate(date: string): string {
    return Utils.formatTimeZone(
      Utils.formatDateWithIDLocale(date, 'DD MMMM YYYY HH:mm Z')
    )
  }

  private onSubmitCancel() {
    if (controller.pickupDetail.shipmentId) {
      controller.cancelPickup({
        shipmentId: controller.pickupDetail.shipmentGroupIds?.length
          ? controller.pickupDetail.shipmentGroupIds.join(',')
          : controller.pickupDetail.shipmentId,
        note: this.cancelReason,
      })
    }
  }

  private onSetDisplayBreadcrumb(isDisplayed: boolean): void {
    const breadcrumb = document.getElementById('breadcrumb')
    if (breadcrumb) {
      if (isDisplayed) {
        breadcrumb.style.display = 'flex'
      } else {
        breadcrumb.style.display = 'none'
      }
    }
  }

  beforeDestroy(): void {
    EventBus.$off(EventBusConstants.CANCEL_PICKUP_SUCCESS)
    controller.setPickupDetail(new PickupDetail())
    this.onSetDisplayBreadcrumb(true)
  }
}
